import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import 'scss/main.scss';
import { SEO } from 'components';
import Nav from '../components/nav';
import Masthead from '../components/masthead';
import Footer from '../components/footer';

function AboutPage({ data: { prismicAbout }, location }) {
    const { data, lang } = prismicAbout;

    return (
        <>
            <SEO
                bodyClass="t-about"
                description={data.page_meta_description}
                lang={lang}
                pathname={location.pathname}
                title={data.page_title}
            />

            <Nav lang={lang} />

            <div className="b-page">
                <div className="b-wrap">
                    <div className="b-page__wrap">
                        <Masthead
                            image={data.image}
                            alt={data.image.alt}
                            title={data.title.text}
                            content={data.content.html}
                            subtitle={data.skill_title}
                            list={data.skills}
                        />
                    </div>
                </div>

                <Footer lang={lang} />
            </div>
        </>
    );
}

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

export const query = graphql`
    query($id: String) {
        prismicAbout(id: { eq: $id }) {
            lang
            uid
            data {
                page_title
                page_meta_description
                title {
                    text
                }
                content {
                    html
                }
                image {
                    url
                    alt
                    dimensions {
                        height
                        width
                    }
                }
                skill_title
                skills {
                    skill {
                        id
                        document {
                            data {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default AboutPage;
